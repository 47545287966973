<template>
  <router-link class="normal-text" :to="{
    name: route, params: { [param]: item.id },
  }">
    <slot></slot>
  </router-link>
</template>


<script>
import router from "@/router";

export default {
  name: "link-to-resource",
  props: {
    item: {
      type: Object
    },
    route: {
      default: 'bridge-inventory',
      type: String,
    },
    param: {
      default: 'id',
      type: String,
    }
  },
};
</script>
