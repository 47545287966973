<template>
  <v-container fluid>
    <AreYouSureModal resource="inspection" prefix="custom" :id="selectedId" :dialog="deleteDialog"
      @toggle="toggleDeleteModal" />
    <v-layout child-flex>
      <v-card>
        <v-data-table :footer-props="{ 'items-per-page-text':''}" :no-data-text="$t('No data available')" :headers="headers" :items="inspections" :items-per-page="15"
          :sort-by="['inspection_year', 'inspection_type']" :sort-desc="[true, false]" multi-sort
          class="elevation-1 outlined">
          <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
            <LinkToResource :key="header.value" :item="item" route="bridge-inspection-details" param="inspectionId">{{
              item[header.value] }}
            </LinkToResource>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>INSPECTION LIST</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <!-- Inspection Dialog -->
              <v-dialog v-model="dialogInspection" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green" dark class="mb-2" v-bind="attrs" v-on="on"
                    v-if="$store.getters.get_access('module_bridge_management', 2)">
                    New Inspection
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form ref="newInspectionForm" v-model="valid">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select v-model="editedItem.inspection_type" :items="inspection_types" item-text="type"
                              item-value="id" :label="$t('inspection-type')">
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="editedItem.inspector_name" label="Inspector Name"
                              :counter="80"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="editedItem.inspection_year" label="Inspector Year" :counter="4"
                              type="number"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="editedItem.inspection_date" label="Inspection Date"
                              type="date"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <!-- Actions -->
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="close"> Cancel </v-btn>
                    <v-btn color="green" @click="save" :disabled="!valid">
                      {{ $t('Save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="showInspectionDetail(item)">
                  mdi-note-text-outline
                </v-icon>
              </template>
              <span>View Bridge Inspection detail</span>
            </v-tooltip>
            <v-tooltip top v-if="$store.getters.get_access('module_bridge_management', 2)">
              <template v-slot:[`activator`]="{ on, attrs }">
                <v-icon color="blue" medium class="mr-2" v-bind="attrs" v-on="on" @click="toggleDeleteModal(item.id)">
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
          <!-- </template> -->
        </v-data-table>
      </v-card>
    </v-layout>
    <success-error-modal :dialog="dialog" :success="success" :successMessage="successMessage" :errorMessage="errorMessage"
      :errorData="errorData" @toggleModal="toggleSuccessModal"></success-error-modal>
  </v-container>
</template>



<script>
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import router from "@/router";
import InspectionService from "@/services/InspectionService";
import SuccessErrorModal from "@/components/main/SuccessErrorModal.vue";
import AreYouSureModal from "@/components/main/AreYouSureModal.vue";
import LinkToResource from "@/components/main/LinkToResource.vue";

export default {
  name: "BridgeInspectionHistory",
  components: {
    SuccessErrorModal,
    AreYouSureModal,
    LinkToResource,
  },
  data() {
    return {
      dialogInspection: false,
      dialogInspectionDetail: false,
      dialogDelete: false,
      valid: false,
      selectedId: null,
      deleteDialog: false,
      inspections: [],
      id: "",
      inspection_type: "",
      inspection_date: "",
      inspector_name: "",

      editedItem: {
        inspection_type: null,
        inspector_name: null,
        inspection_year: null,
        inspection_date: null,
        inspection_data_status: 'PENDIN'
      },
      inspection_types: [
        {
          id: "A",
          type: "A",
        },
        {
          id: "B",
          type: "B",
        },
      ],
      success: false,
      dialog: false,
      errorData: "",
      successMessage: {
        en: "Inspection successfully created !",
        la: "Inspection successfully created !",
      },
      errorMessage: {
        en: "An error has occured while trying to create a new inspection.",
        la: "An error has occured while trying to create a new inspection.",
      },
    };
  },
  computed: {
    bridgeId() {
      return this.$route.params.id;
    },
    formTitle() {
      return "New Inspection";
    },
    description() {
      return this.$i18n.locale === "la" ? "description_lao" : "description_eng";
    },

    headers() {
      return [
        {
          text: this.$t("year-insp"),
          value: "inspection_year",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("type-insp"),
          value: "inspection_type",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("name-insp"),
          value: "inspector_name",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("date-insp"),
          value: "inspection_date",
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("inspection_data_status"),
          value: `InspectionDataStatus.${this.description}`,
          sortby: true,
          color: "blue",
          align: "center",
        },
        {
          text: this.$t("hi"),
          value: `hi`,
          sortby: true,
          color: "blue",
          align: "center",
        },
        { text: "ACTIONS", value: "actions", color: "blue", align: "center" },
      ];
    },
  },
  watch: {
    dialogInspection(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async mounted() {
    this.inspections = (await InspectionService.getInspectionWithHI(this.bridgeId)).data;
  },
  methods: {
    async toggleDeleteModal(id) {
      if (!this.deleteDialog) {
        this.selectedId = id;
        this.deleteDialog = true;
      } else {
        this.selectedId = null;
        this.deleteDialog = false;
        this.$emit("update");
      }
    },
    async showInspectionDetail(item) {
      router.push({
        name: "bridge-inspection-details",
        params: { inspectionId: item.id },
      });
    },
    editItemDetail(item) {
      router.push({
        name: "edit-inspection-detail",
        params: { id: item.id, inspection: item },
      });
    },
    close() {
      this.dialogInspection = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async save() {
      this.editedItem.bridge_id = this.bridgeId;
      this.editedItem.id = this.editedItem.bridge_id.toString() + "x" + this.editedItem.inspection_year.toString() + "x" + this.editedItem.inspection_type.toString();
      try {
        if (this.inspections.map(x => x.id).includes(this.editedItem.id)) {
          throw new Error("The key year / type : (" + this.editedItem.inspection_year + " / " + this.editedItem.inspection_type + ") already exists");
        }
        const newInspection = (
          await InspectionService.upsertInspection(this.editedItem)
        ).data;
        this.$emit("newInspection", newInspection);
        this.close();
        this.success = true;
        this.dialog = true;
        this.errorData = "";
      } catch (error) {
        this.close();
        this.errorData = error.message;
        this.success = false;
        this.dialog = true;
      }
    },
    toggleSuccessModal() {
      if (this.dialog) {
        this.dialog = false;
      }
    },
  },
};
</script>